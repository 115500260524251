import request from '../utils/request'
// 收藏课程
export function editCollect(params) {
  return request({
    url: 'edu/lesson/editCollect',
    method: 'get',
    params: params
  })
}

// 浏览课程
export function lessonview(params) {
  return request({
    url: 'personal/History/lessonview',
    method: 'get',
    params: params
  })
}
// 浏览课程
export function bbsview(params) {
  return request({
    url: 'personal/History/bbsview',
    method: 'get',
    params: params
  })
}
// 我的足迹--浏览课程--清除记录
export function lessonviewDel(params) {
  return request({
    url: 'personal/History/lessonviewDel',
    method: 'get',
    params: params
  })
}
// 评测记录
export function evaluate(params) {
  return request({
    url: 'personal/History/evaluate',
    method: 'get',
    params: params
  })
}
