<template>
  <div class="browseLesson">

    <!-- <p class="myclass-title">
      <span class="title-text">浏览课程</span>
      <span class="title-border"></span>
    </p> -->
    <headers ref="headers" :headname="headName"></headers>

    <!-- 有数据展示 -->
    <div v-show="count>0" class="browse_list">
      <div v-for="(item,index) in list" :key="index" class="zhul_sy_zyList">
        <div class="zhul_nzy_limg">
          <img :src="item.thumb" alt="">
        </div>
        <div class="zhul_nzy_rinfo">
          <p class="top_title">{{ item.lesson_name }}</p>
          <p class="info_center">
            共有{{ item.schedule_total }}课时，已学{{ item.look_count }}课时
          </p>
          <div class="info_bottom">
            <span class="price_text">价格</span>
            <span class="price">￥{{ item.price }}</span>
            <span v-if="item.is_buy===1" class="button" @click="threadDetail(item)">立即听课</span>
            <span v-else class="button" @click="threadDetail(item)">查看课程</span>
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <div class="feny">
        <el-pagination v-show="count>10" class="page" background layout="prev, pager, next" :page-size="params.limit" :total="count" @current-change="handleCurrentChange"></el-pagination>
      </div>

    </div>

    <!-- 清除按钮 -->
    <div>
      <span v-if="count>0" class="cancelCollect" @click="removeData">清空记录</span>
    </div>

    <!-- 无数据显示 -->
    <noData v-show="count===0" ref="noData" :msg="msg"></noData>
    <!-- <div v-show="count===0" class="noDataShow">
      <img src="@/assets/image/kong.png" alt="">
      <p>您还没有浏览过课程，快去看看吧~</p>
    </div> -->
  </div>
</template>

<script>
import { lessonview, lessonviewDel } from '@/api/lesson.js'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
export default {
  name: '',
  components: { headers, noData },

  data() {
    return {
      headName: '浏览课程',
      msg: '您还没有浏览过课程，快去看看吧~',
      count: 0,
      url: '', // 课程详情链接
      list: [],
      params: {
        page: 1,
        limit: 10
      }
    }
  },

  computed: {},

  created() {
    this.getlist()
  },

  mounted() { },

  methods: {
    saveCookies(data){
      Cookies.set('categoryId', data.category_id)
      Cookies.set('categoryName', data.category_name)
      Cookies.set('sourceId', data.source_id)
      Cookies.set('combineId', data.combination_id)
      Cookies.set('combineName', data.combination_name)
    },
    getlist() {
      lessonview(this.params).then(res => {
        this.list = res.result.list
        this.count = res.result.count
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    threadDetail(item) {
      const origin = window.location.origin
      if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
        if(item.zkb_pro){
          this.saveCookies(item.zkb_pro)
        }
        const url = window.location.origin+`/kb_timetable?combine_id=${item.zkb_pro?item.zkb_pro.combine_id:''}&lesson_id=${item.lesson_id}&lesson_name=${item.lesson_name}#i=2`
        console.log(url, 'url')
        window.open(url, '_blank')
      } else {
        window.open(item.url, '_blank')
      }
      // window.open(item.url, '_blank')
    },

    // 清除浏览记录
    removeData() {
      console.log('清除浏览记录')
      lessonviewDel().then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.count = 0
          this.list = []
        }
      })
    },

    // 分页
    handleCurrentChange(val) {
      // 当前点击页数
      this.params.page = val
      this.getlist()
    }
  }
}
</script>

<style lang="scss" scoped>
.browseLesson {
  width: 965px;
  padding: 0 0 20px 0;
  min-height: 900px;
  position: relative;
  font-family: "PingFangSC-Regular";
}
.myclass-title {
  padding: 26px 60px 12px 10px;
  width: 100%;
  font-size: 19px;
  position: relative;
  text-align: left;
}
.title-border {
  position: absolute;
  left: 0;
  bottom: 16px;
  width: 4px;
  height: 20px;
  z-index: 2;
  background: #f04e4f;
}
.browse_list {
  margin-left: 30px;
  // pad: 0 13px;
  // background-color:#fff;
}
.zhul_sy_zyList {
  float: left;
  width: 100%;
  border-bottom: 1px solid #dddddd;
  padding: 16px 0;
}
.zhul_nzy_limg {
  float: left;
  width: 160px;
  height: 120px;
  margin-right: 15px;
}
.zhul_nzy_limg img {
  width: 100%;
  height: 100%;
}
.zhul_nzy_rinfo {
  float: right;
  width: 750px;
}
.top_title {
  font-size: 16px;
  max-width: 500px;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info_center {
  width: 100%;
  margin-top: 20px;
  line-height: 20px;
  font-size: 12px;
  text-align: left;
}
.info_bottom {
  widows: 100%;
  line-height: 30px;
  margin-top: 30px;
  text-align: left;
}
.price_text {
  font-size: 12px;
}
.price {
  color: #ee2e2e;
  margin-left: 15px;
  font-size: 12px;
  display: inline-block;
  width: 100px;
}
.button {
  width: 90px;
  // margin-left: 55px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  display: inline-block;
  background-color: #ee2e2e;
  cursor: pointer;
}
.noDataShow {
  position: absolute;
  width: 180px;
  height: 180px;
  top: 50%;
  left: 50%;
  margin-top: -90px;
}
.noDataShow img {
  display: block;
  margin: auto;
}
.noDataShow p {
  text-align: center;
  font-size: 14px;
  font-family: "微软雅黑", "Aria", Arial, Helvetica, sans-serif;
  color: #999999;
  margin-top: 30px;
}
.feny {
  display: inline-block;
  margin-top: 20px;
}
.cancelCollect {
  width: 126px;
  line-height: 28px;
  color: #ee2e2e;
  border: 1px solid #ee2e2e;
  text-align: center;
  background-color: #fff;
  display: inline-block;
  border-radius: 14px;
  cursor: pointer;
  margin-top: 120px;
}
</style>
